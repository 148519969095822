function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Base64 } from 'js-base64';
import { publicRegistrationConstants } from '../constants/actions';
import { codeKeys } from '../helpers/helpers';
function publicRegistration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case publicRegistrationConstants.GET_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingForm: true,
        successForm: false
      });
    case publicRegistrationConstants.GET_FORM_SUCCESS:
      {
        var _action$response = action.response,
          formFields = _action$response.formFields,
          form = _action$response.form;
        var codedFormFields = {};
        var _codeKeys = codeKeys(form.elements),
          encodedObjects = _codeKeys.encodedObjects,
          encodedIds = _codeKeys.encodedIds;
        if (formFields) {
          var regexp = /[ .¿?#\-!@%^&*()<>{}[\]\\/|+=:;,]/;
          Object.entries(action.response.formFields).forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];
            var _key$split = key.split('|'),
              _key$split2 = _slicedToArray(_key$split, 2),
              baseKey = _key$split2[0],
              field = _key$split2[1];
            if (regexp.test(key)) {
              var encodedKey = Base64.encode(key);
              if (encodedIds.includes(encodedKey)) {
                codedFormFields[encodedKey] = value;
              }
            } else {
              codedFormFields[key] = value;
            }
            if (baseKey.startsWith('guest_') && field) {
              if (regexp.test(field)) {
                var _encodedKey = Base64.encode(field);
                if (encodedIds.includes(_encodedKey)) {
                  codedFormFields["".concat(baseKey, "|").concat(_encodedKey)] = value;
                }
              } else {
                codedFormFields[key] = value;
              }
            }
          });
        }
        return _objectSpread(_objectSpread({}, action.response), {}, {
          formFields: codedFormFields,
          isPagingShown: true,
          loadingForm: false,
          successForm: true,
          codedForm: {
            elements: encodedObjects,
            updatedOn: action.response.form.updatedOn
          },
          codedFormIds: encodedIds
        });
      }
    case publicRegistrationConstants.GET_FORM_FAILURE:
      return _objectSpread(_objectSpread({}, action), {}, {
        message: action.response,
        isPagingShown: true,
        loadingForm: false,
        successForm: false
      });
    case publicRegistrationConstants.GET_FORM_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingForm: true,
        successForm: false
      });
    case publicRegistrationConstants.GET_FORM_PREVIEW_SUCCESS:
      {
        var _codeKeys2 = codeKeys(action.response.form.elements),
          _encodedObjects = _codeKeys2.encodedObjects;
        return _objectSpread(_objectSpread({}, action.response), {}, {
          codedForm: {
            elements: _encodedObjects,
            updatedOn: action.response.form.updatedOn
          },
          isPagingShown: true,
          loadingForm: false,
          successForm: true
        });
      }
    case publicRegistrationConstants.GET_FORM_PREVIEW_FAILURE:
      return _objectSpread(_objectSpread({}, action), {}, {
        message: action.response,
        isPagingShown: true,
        loadingForm: false,
        successForm: false
      });
    case publicRegistrationConstants.REGISTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: true,
        loadingRegister: true,
        errors: undefined
      });
    case publicRegistrationConstants.REGISTER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: false,
        loadingRegister: false,
        successRegister: true,
        errors: undefined
      });
    case publicRegistrationConstants.REGISTER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: true,
        message: action.response.message,
        errors: action.response.errors,
        successRegister: false,
        loadingRegister: false
      });
    case publicRegistrationConstants.CREATE_PAYMENT_INTENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: true,
        loadingCreatePaymentIntent: true,
        message: undefined,
        errors: undefined
      });
    case publicRegistrationConstants.CREATE_PAYMENT_INTENT_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.response), {}, {
        isPagingShown: false,
        loadingCreatePaymentIntent: false,
        successCreatePaymentIntent: true,
        message: undefined,
        errors: undefined
      });
    case publicRegistrationConstants.CREATE_PAYMENT_INTENT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: true,
        message: action.response.message,
        errors: action.response.errors,
        loadingCreatePaymentIntent: false,
        successCreatePaymentIntent: false
      });
    case publicRegistrationConstants.DECLINE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeclineSuccess: false,
        isDeclineLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined
      });
    case publicRegistrationConstants.DECLINE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeclineSuccess: true,
        isDeclineLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined
      });
    case publicRegistrationConstants.DECLINE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeclineSuccess: false,
        isDeclineLoading: false,
        message: action.response.message
      });
    case publicRegistrationConstants.CANCEL_CHECKOUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPagingShown: true,
        clientSecret: undefined,
        loadingCreatePaymentIntent: undefined,
        successCreatePaymentIntent: undefined,
        message: undefined,
        errors: undefined
      });
    default:
      return state;
  }
}
export default publicRegistration;